import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";
import NoLinkCards from "../../components/card/no-link-cards";
import WaFdBankStatesMap from "../../assets/wafd-bank-9-states-plain-map.svg";
import ServiceStatesLinks from "../../components/service-states/service-states-links";

const SmallBusinessBankingSpanish = () => {
  const imgData = useStaticQuery(graphql`
    {
      sbCreditCard: file(relativePath: { eq: "cards/thumbnail-small-business-credit-card-102423.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      sbChecking: file(relativePath: { eq: "cards/thumbnail-business-checking-102623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      sbSavings: file(relativePath: { eq: "cards/thumbnail-business-savings-102623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      lineOfCredit: file(relativePath: { eq: "thumbnail-business-lines-of-credit-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      zelleImage: file(relativePath: { eq: "thumbnail-business-zelle-042224.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      businessTermLoansImage: file(relativePath: { eq: "thumbnail-business-term-loans-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);
  const breadcrumbData = [
    {
      id: 1,
      url: "/es/banca-de-negocios",
      title: "Banca de negocios"
    },
    {
      id: 2,
      active: true,
      title: "Banca para pequeñas empresas"
    }
  ];

  const SEOData = {
    title: "El banco para pequeñas empresas",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "El banco para pequeñas empresas"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Obtenga información sobre banca para pequeñas empresas en WaFd Bank. Nuestros servicios para pequeñas empresas incluyen cuentas bancarias, préstamos y más."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-de-negocios"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-small-business-banking-02-250.jpg"
      }
    ]
  };

  const bestBankVideoData = {
    title: "Why WaFd Bank is the Best Bank for Small Business",
    classTitle: "text-center mb-0",
    showTitle: true,
    vimeoId: "495516934",
    minHeight: "360px",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const builtForBusinessVideoData = {
    title: "Creado para empresas",
    classTitle: "mb-0 text-center",
    showTitle: true,
    vimeoId: "899307145",
    minHeight: "360px",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const businessProductsData = {
    sectionClass: "bg-white",
    sectionId: "business-products-section",
    cards: [
      {
        id: "sb-credit-card",
        title: "Tarjeta de crédito para pequeñas empresas",
        titleClass: "text-success",
        text: "Diseñada para propietarios de pequeñas empresas, esta tarjeta ofrece flexibilidad y conveniencia, Además de recompensas de devolución de efectivo!",
        image: {
          imgVariable: imgData.sbCreditCard.childImageSharp.gatsbyImageData,
          altText: "Hombre de negocios sosteniéndo una tarjeta de crédito mientras mira su teléfono movil."
        },
        button: {
          id: "sb-credit-card-apply-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          externalLink: true,
          target: "blank",
          class: "btn-primary btn-block mt-3",
          text: "Aplique ahora",
          url: "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-community-business-cash-back-rewards-visa-credit-card&v_id=null&_st=1699306304820&ga_id=3a775831-c358-4f65-b564-0f8abdf9bf8c"
        }
      },
      {
        id: "sb-checking",
        title: "Cuenta de cheques de Negocio",
        titleClass: "text-success",
        text: "Compare nuestras cuentas comerciales para encontrar la cuenta adecuada para su negocio.",
        image: {
          imgVariable: imgData.sbChecking.childImageSharp.gatsbyImageData,
          altText: "Hombre de negocios usando su banca en línea en un teléfono movil."
        },
        button: {
          id: "sb-checking-open-account-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Abrir una cuenta",
          url: "/business-banking/business-checking-account"
        }
      },
      {
        id: "sb-savings",
        title: "Cuenta de ahorros para Negocio",
        titleClass: "text-success",
        text: "Haga crecer su capital comercial abriendo una cuenta de ahorros para pequeñas empresas en WaFd Bank.",
        image: {
          imgVariable: imgData.sbSavings.childImageSharp.gatsbyImageData,
          altText: "Mujer de negocios sonriéndo mientras usa una calculadora portatil."
        },
        button: {
          id: "sb-savings-open-account-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Abrir una cuenta",
          url: "/business-banking/business-savings-account"
        }
      }
    ]
  };

  const businessProductsData2 = {
    sectionClass: "bg-white",
    sectionId: "business-products-section-2",
    cards: [
      {
        id: "business-line-of-credit",
        title: "Linea de credito para Negocio",
        titleClass: "text-success",
        text: "Si necesita pedir prestado fondos para respaldar la entrada de efectivo cíclica o estacional, las líneas de crédito comerciales suelen ser la solución adecuada.",
        image: {
          imgVariable: imgData.lineOfCredit.childImageSharp.gatsbyImageData,
          altText: "Mujar en su tableta digital, mirando una maceta con flores en un invernadero."
        },
        button: {
          id: "sb-banker-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Buscar un banquero de Negocio",
          url: "/business-banking/near-me"
        }
      },
      {
        id: "use-zelle-for-business",
        title: "Use Zelle<sup>&reg;</sup> para su Negocio",
        titleClass: "text-success",
        text: "Reciba fácilmente pagos de sus clientes en cualquier lugar y en cualquier momento en solo minutos! Además, puede utilizar Zelle® para pagar a otras empresas inscritas. ¡Empiece a utilizar los servicios de banco en línea hoy mismo!",
        image: {
          imgVariable: imgData.zelleImage.childImageSharp.gatsbyImageData,
          altText: "Personas comprando flores en un puesto al aire libre con un letrero que dice Zelle aceptado aquí."
        },
        button: {
          id: "sb-banker-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Aprende más sobre Zelle",
          url: "/business-banking/online-banking/zelle"
        }
      },
      {
        id: "business-term-loans",
        title: "Prestamo a plazo comercial",
        titleClass: "text-success",
        text: "Los préstamos comerciales a plazo pueden ser una excelente solución para necesidades como financiamiento de equipos, aumentos de capital, financiamiento de futuras expansiones y más.",
        image: {
          imgVariable: imgData.businessTermLoansImage.childImageSharp.gatsbyImageData,
          altText: "Chef sonriéndo en un estacionamiento mientras sostiene una tableta."
        },
        button: {
          id: "locations-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Aplica en una sucursal",
          url: "/es/sucursales"
        }
      }
    ]
  };

  const sbStatePageLinks = {
    stateData: [
      {
        id: "arizona-state-page-link",
        esName: "Arizona",
        esUrl: "/about-us/business-banking/arizona"
      },
      {
        id: "california-state-page-link",
        esName: "California",
        esUrl: "/about-us/business-banking/california"
      },
      {
        id: "idaho-state-page-link",
        esName: "Idaho",
        esUrl: "/about-us/business-banking/idaho"
      },
      {
        id: "nevada-state-page-link",
        esName: "Nevada",
        esUrl: "/about-us/business-banking/nevada"
      },
      {
        id: "new-mexico-state-page-link",
        esName: "New Mexico",
        esUrl: "/about-us/business-banking/new-mexico"
      },
      {
        id: "oregon-state-page-link",
        esName: "Oregon",
        esUrl: "/about-us/business-banking/oregon"
      },
      {
        id: "texas-state-page-link",
        esName: "Texas",
        esUrl: "/about-us/business-banking/texas"
      },
      {
        id: "utah-state-page-link",
        esName: "Utah",
        esUrl: "/about-us/business-banking/utah"
      },
      {
        id: "washington-state-page-link",
        esName: "Washington",
        esUrl: "/about-us/business-banking/washington-state"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>El banco para pequeñas empresas</h1>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Por qué WaFd Bank es el mejor banco para pequeñas empresas</h2>
              <h4>
                En WaFd Bank creemos en las pequeñas empresas y ofrecemos productos y servicios diseñados para ayudarles
                a establecerse y crecer. Pregunte acerca de los servicios que le ayudarán a optimizar sus operaciones,
                aumentar el dinero que entra a su empresa y ayudar que clientes sigan volviendo por más.
              </h4>
            </div>
            <div className="col-md-6">
              <VimeoVideoFrame {...builtForBusinessVideoData} />
            </div>
          </div>
        </div>
      </section>
      <NoLinkCards {...businessProductsData} />
      <section className="container pt-0">
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <h2 className="text-success">Servicios para pequeñas empresas</h2>
            <p>
              Mantenerse al día con las operaciones diarias y brindar experiencias excepcionales a los clientes es
              crucial para el éxito a largo plazo. Ofrecemos servicios comerciales como CollectEarly<sup>&trade;</sup>{" "}
              para que pueda pagar sus facturas en semanas, no en meses. Consúltanos sobre este y otros servicios para
              agilizar tus operaciones.
            </p>
            <Link
              className="btn btn-primary w-100 w-sm-auto"
              to="/business-banking/business-services"
              id="business-services-cta"
            >
              Aprenda sobre los Servicios Empresariales de WaFd
            </Link>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/thumbnail-business-banking-team-050124.jpg"
              alt="Mujer sonriéndo en una junta de negocios."
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../images/thumbnail-small-business-team.jpg"
                alt="Personas de negocio dándose la mano."
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2>Conozca a nuestro equipo bancario para pequeñas empresas!</h2>
              <p>
                Hable con un prestamista comercial local sobre las opciones de préstamo, incluyendo préstamos y líneas
                de crédito personalizadas según las necesidades de su empresa. Nos tomamos el tiempo para comprender su
                negocio, aumentar el dinero que entra a su empresa y las oportunidades de aprovechar el financiamiento
                para que pueda posicionarse mejor para el éxito.
              </p>
              <Link to="/business-banking/near-me" className="btn btn-primary w-100 w-sm-auto">
                Encuentre un banquero para pequeñas empresas
              </Link>
            </div>
          </div>
        </div>
      </section>
      <NoLinkCards {...businessProductsData2} />

      <section className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <img className="w-100" src={WaFdBankStatesMap} alt="WaFd Bank States Map." />
          </div>
          <div className="col-md-6 col-lg-9">
            <h2 className="font-weight-semi-bold">
              WaFd Bank es el Mejor Banco para pequeñas empresas en el oeste de EE. UU.
            </h2>
            <ServiceStatesLinks {...sbStatePageLinks} />
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default SmallBusinessBankingSpanish;
